.leaflet-control-fullscreen a {
    background:#fff url(../../images/full.png) no-repeat;
    background-size: 16px 16px;
    background-position: 5px !important;
    }
    .leaflet-touch .leaflet-control-fullscreen a {
      background-position: 2px 2px;
      }
    .leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position:0 -26px;
      }
    .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position: 2px -24px;
      }
  
  /* Do not combine these two rules; IE will break. */
  .leaflet-container:-webkit-full-screen {
    width:100%!important;
    height:100%!important;
    }
  .leaflet-container.leaflet-fullscreen-on {
    width:100%!important;
    height:100%!important;
    }
  
  .leaflet-pseudo-fullscreen {
    position:fixed!important;
    width:100%!important;
    height:100%!important;
    top:0!important;
    left:0!important;
    z-index:99999;
    }
  
  @media
    (-webkit-min-device-pixel-ratio:2),
    (min-resolution:192dpi) {
      .leaflet-control-fullscreen a {
      }
    }