.result-tooltip {
  background-color: white;
  border-width: medium;
  border-color: #5f58c4;
  font-size: smaller;
}
.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  border: dotted;
  border-color: #5f58c4;
  font-size: smaller;
}
.plus-length {
  padding-left: 45px;
  display: none;
}
