/* .leaflet-control {
  background-color: white;
} */
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.leaflet-container {
  background-color: rgba(255, 255, 255, 0) !important;
}
.leaflet-popup-content-wrapper {
  background: rgba(47, 47, 47, 0.5);
  backdrop-filter: blur(5px);
}
.leaflet-popup-content {
  color: white;
}
.leaflet-popup-content table th {
  border: none;
}
.leaflet-popup-content h6,
.leaflet-popup-content h4,
.leaflet-popup-content h3 {
  color: white;
}
.leaflet-popup-content table tr td,
.leaflet-popup-content table tr th {
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.dark-layout .leaflet-popup-content table tr td,
.dark-layout .leaflet-popup-content table tr th {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.leaflet-control-layers {
  background-color: rgba(47, 47, 47, 0.5) !important;
}
.leaflet-control-container .leaflet-left .leaflet-bar,
.leaflet-control-zoom-in {
  background-color: rgba(47, 47, 47, 0.5) !important;
  color: white !important;
}
.leaflet-control-container .leaflet-left .leaflet-bar,
.leaflet-control-zoom-out {
  background-color: rgba(47, 47, 47, 0.5) !important;
  color: white !important;
}
.leaflet-control-container
  .leaflet-bottom
  .leaflet-control-scale
  .leaflet-control-scale-line {
  background-color: rgba(47, 47, 47, 0.5) !important;
}
.fiVUOU .leaflet-contextmenu {
  background-color: rgba(47, 47, 47, 0.5) !important;
  backdrop-filter: blur(5px);
}
.leaflet-control-fullscreen-button {
  background-color: rgba(47, 47, 47, 0.5) !important;
  color: white !important;
}
.leaflet-verticalcenter {
  position: absolute !important;
  z-index: 1000 !important;
  pointer-events: none !important;
  top: 50% !important; /* possible because the placeholder's parent is the map */
  transform: translateY(
    -50%
  ) !important; /* using the CSS3 Transform technique */
  padding-top: 10px !important;
}
.leaflet-verticalcenter .leaflet-control {
  margin-bottom: 10px !important;
}
.leaflet-control-container .leaflet-right .leaflet-control-buttons {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.leaflet-tooltip {
  color: white !important;
  background-color: rgba(47, 47, 47, 0.5) !important;
  backdrop-filter: blur(5px);
  border: none;
}

/* .leaflet-popup-tip-container {
  width: 24px;
} */

/* .leaflet-container.eraser-cursor-enabled {
  cursor: url(../../images/Eraser.png), auto !important;
} */
/* .leaflet-container.eraser-cursor-disabled {
  cursor: default !important;
}
.leaflet-interactive  .eraser-cursor-enabled {
  cursor: url(../../images/Eraser.png), auto !important;
} */
