.paperbg {
  position: relative;
  width: 100%;
  font: 1em Arial;
  z-index: 1;
  overflow: hidden;
  border: 1px solid rgba(57, 80, 57, 0.443);
  border-radius: 10px;
  /* box-shadow: 0px 5px 25px 0px rgba(4, 91, 8, 0.224); */
  background-color: #16d51600;
}
.paperbg::before {
  content: "";
  position: absolute;
  top: -8px; /* -blur */
  left: -8px; /* -blur */
  width: calc(100% + 16px); /* 100% + blur * 2 */
  border: 1px solid rgb(24, 4, 4);
  background-position: 10%;
  background-color: rgba(6, 72, 39, 0.442);
  filter: blur(1990px);
  z-index: -1;
}
.paperVideo {
  position: relative;

  width: 100%;
  font: 1em Arial;
  z-index: 1;
  overflow: hidden;
  border: 1px solid rgba(57, 80, 57, 0.443);
  border-radius: 10px;
  /* box-shadow: 0px 5px 25px 0px rgba(4, 91, 8, 0.224); */
  background-color: #16d51600;
}
.paperVideo::before {
  content: "";
  position: absolute;
  top: -8px; /* -blur */
  left: -8px; /* -blur */
  width: calc(100% + 16px); /* 100% + blur * 2 */
  border: 1px solid rgb(24, 4, 4);
  background-position: 10%;
  background-color: rgba(6, 72, 39, 0.442);
  filter: blur(1990px);
  z-index: -1;
}
.child img {
  margin: 15px;
  /* border-radius: 4px; */
  /* Rounded border */
  padding: 5px;
}
