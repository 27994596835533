@media only screen and (max-width: 1600px) {
  .card {
    height: 29vh !important;
    /* border: 1px solid red !important; */
  }
}

@media only screen and (max-width: 1361px) {
  .card {
    height: 21vh !important;
    /* border: 1px solid red !important; */
  }
}
@media only screen and (max-width: 950px) {
  .card {
    height: 29.3vh !important;
    /* border: 1px solid red !important; */
  }
}
