.search-box {
  margin: 0 5px;
  width: 300px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(47, 47, 47, 0.5);
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  backdrop-filter: blur(5px);
}
.search-bar {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
}
.drop-down-content {
  width: 300px;
  margin-top: 0.5em;
  border-radius: 20px;
  background-color: rgba(47, 47, 47, 0.5);
  color: white;
  margin-left: 0.5em;
  backdrop-filter: blur(5px);
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}
.dropdown-item {
  color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-item:hover {
  color: white;
  cursor: pointer;
}
.input-search {
  background-color: black;
  border: none;
  width: 80%;
  background-color: transparent;
  color: white;
}
.input-search::placeholder {
  color: rgba(255, 255, 255, 0.651);
}
.search-icon {
  color: white;
  margin: 0 10px;
}
