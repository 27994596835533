.leaflet-control-buttons {
  margin-left: 0px !important;
  margin-top: 0px !important;
  background: transparent;
}

.leaflet-selected {
  background-color: #2c374b !important;
  color: #fff !important;
}

.leaflet-control-buttons .leaflet-control-buttons-item:focus {
  outline: none;
}

.leaflet-control-buttons-item {
  position: relative;
  display: inline-block;
  background-color: rgba(47, 47, 47, 0.5) !important;
  backdrop-filter: blur(5px);
}
.leaflet-control-buttons-item i {
  color: white;
}
.leaflet-control-layers-base label {
  color: white !important;
}
.leaflet-control-buttons-item .leaflet-control-buttons-tooltip {
  visibility: hidden;
  white-space: nowrap;
  margin-top: -1px;
  right: 30px;
  width: auto;
  background-color: rgba(47, 47, 47, 1);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 0px 5px;
  position: absolute;
  opacity: 0.8;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.leaflet-control-buttons-item:hover .leaflet-control-buttons-tooltip {
  visibility: visible;
}
