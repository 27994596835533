.leaflet-container .leaflet-control-zoomlabel {
    background-color: rgba(47, 47, 47, 0.5);
    /* box-shadow: 0 0 5px #bbb; */
    padding: 0 5px;


margin-right: 0;
    color: rgb(255, 255, 255);
    font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    backdrop-filter: blur(5px);
    /* border-top-left-radius: 5px; */
}
.leaflet-control-attribution {
    background-color: rgba(47, 47, 47, 0.5) !important;
    backdrop-filter: blur(5px);
    color: white;
}
.leaflet-control-attribution a {
    color: white ;
}
.leaflet-control-scale {
    margin-right: 0 !important;
}
.leaflet-control-scale .leaflet-control-scale-line  {
    background-color: rgba(47, 47, 47, 0.5) !important;
    backdrop-filter: blur(5px);
    color: white;
    border: none;
    text-align: right;
    width: 100% !important;
}
